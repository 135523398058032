import { pick } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import ROUTES from '@app/routes';
import { workflowNames } from '@app/translations';

// Convert `obj` to a key/value array, filter on __typename then convert key/value back to an object
const filterTypename = (obj) => Object.fromEntries(Object.entries(obj).filter(([key]) => key !== '__typename'));

// Delete recursively the property __typename of the object
export const deleteTypename = (obj) => {
  const isArray = Array.isArray(obj);
  if (typeof obj === 'object' && obj !== null) {
    obj = filterTypename(obj);
    for (const key in obj) {
      obj[key] = deleteTypename(obj[key]);
    }
  }
  // Convert the object back to an array if needed
  return isArray ? Object.values(obj) : obj;
};

export const findByKey =
  (key) =>
  ({ key: formKey }) =>
    formKey === key;

const padWithZero = (n) => String(n).padStart(2, '0');

export const durationInMinutes = (time) => {
  const hours = Math.floor(time / 3600);
  const paddedHours = hours ? `${padWithZero(hours)}:` : '';
  const paddedMins = padWithZero(Math.floor((time % 3600) / 60));
  const paddedSeconds = padWithZero(Math.floor(time % 60));

  return `${paddedHours}${paddedMins}:${paddedSeconds}`;
};

export const isDebug = () => !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const precise = (x, presision = 3) => Number(Number.parseFloat(x).toPrecision(presision));

/**
 *
 * @param {*} value
 * @param {('STRING' |  'PERCENT'  |  'NUMBER' | 'RATING'  )} type
 * @param {function} t
 */
export const formatByType = (value, type = 'STRING', t = (_) => _) => {
  if (!type || type === 'STRING') {
    return value;
  }

  if (value !== 0 && !value) {
    return t('N/A');
  }

  if (type === 'PERCENT') {
    return t('{{number}}%', { number: precise(value) });
  }

  if (type === 'RATING') {
    return t('{{number}}/5', { number: precise(value) });
  }

  if (type !== 'NUMBER') {
    return value;
  }

  if (value >= 1000000000) {
    return precise(value / 1000000000) + t('B');
  }

  if (value >= 1000000) {
    return precise(value / 1000000) + t('M');
  }

  if (value >= 1000) {
    return precise(value / 1000) + t('K');
  }

  return value || 0;
};

export const toDate = (value) => {
  if (!value || moment.isDate(value)) {
    return value;
  }
  return moment(value, 'YYYY-MM-DD').toDate();
};

export const formatDateToYYYYMMDD = (date) => moment(date).format('YYYYMMDD');

export const formatDateToDashedYYYYMMDD = (date) => (date ? moment(date).format('YYYY-MM-DD') : null);

export const dateDiff = (date1, date2, unitOfTime = 'days') => (date1 && date2 && date2 >= date1 ? moment(date2).diff(moment(date1), unitOfTime) : null);

export const dateDiffToDisplay = (date1, date2, t) => {
  if (!date1 || !date2 || moment(date2).diff(moment(date1), 'days') < 0) {
    return t('N/A');
  }

  const moment1 = moment(date1);
  const moment2 = moment(date2);
  const years = dateDiff(moment1, moment2, 'year');
  moment1.add(years, 'years');

  const months = dateDiff(moment1, moment2, 'months');
  moment1.add(months, 'months');

  const days = dateDiff(moment1, moment2, 'days');
  return years || months || days
    ? [
        ...(years ? [t('{{count}} year', { count: years })] : []),
        ...(months ? [t('{{count}} month', { count: months })] : []),
        ...(days ? [t('{{count}} day', { count: days })] : []),
      ].join(' ')
    : t('N/A');
};

export const timecodeToTime = (duration) => {
  const hours = Math.floor(duration / 3600 / 1000) % 24;
  const minutes = Math.floor(duration / 60 / 1000) % 60;
  const seconds = Math.floor(duration / 1000) % 60;
  const milliseconds = duration % 1000;
  return { hours, minutes, seconds, milliseconds };
};

export const timeToTimecode = ({ hours, minutes, seconds, milliseconds }) => (hours * 3600 + minutes * 60 + seconds) * 1000 + milliseconds;

export const timecodeToStr = (timecode) => {
  const { minutes, seconds, milliseconds } = timecodeToTime(timecode);
  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}.${String(milliseconds).padStart(3, '0')}`;
};

export const timecodeToInputStr = (timecode) => {
  const { minutes, seconds } = timecodeToTime(timecode);
  return `00:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

export const positionToInputStr = (position) => moment.duration(position, 'seconds').format('hh:mm:ss', { trim: false });
export const positionToInputStrInMinute = (position) => moment.duration(position, 'seconds').format('mm:ss', { trim: false });

export const isEmptyOrNull = (o) => !o || isEmpty(o);

export const isChecked = (current, value, multi) => {
  if (multi && Array.isArray(value)) {
    return value.includes(current);
  }

  return value === current;
};

export const sortByProp = (getProp = (x) => x) => (a, b) => {
  const propA = getProp(a);
  const propB = getProp(b);

  if (!propA && !propB) return 0;
  if (!propA) return 1;
  if (!propB) return -1;

  return String(propA).localeCompare(String(propB));
};

export const arrayToCsv = (data) => {
  return [[...Object.keys(data[0])], ...data.map((item) => [...Object.values(item).map((i) => (typeof i === 'string' ? `"${i}"` : i))])]
    .map((e) => e.join(','))
    .join('\n');
};

export const downloadCsv = (csvString, filename = 'file.csv') => {
  // Add a character at the beginning of the string to force excel to open it in UTF-8
  // https://stackoverflow.com/questions/42462764/javascript-export-csv-encoding-utf-8-issue
  const universalBOM = '\uFEFF';

  const link = document.createElement('a');
  link.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + csvString));

  link.download = filename;
  link.click();
  link.remove();
};

export const downloadBlob = (blob, filename = 'blob', path = '') => {
  const url = path || URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();
  link.remove();

  URL.revokeObjectURL(url);
};

export const objectToBlob = (object) => new Blob([JSON.stringify(object, null, 2)], { type: 'application/json' });

export const renameSolutionKeyFields = (c) => ({
  ...c,
  keyId: c.key.id,
  key: c.key.name,
  type: c.key.type,
  options: c.key.options,
});

export const getTemplateFromSolutionType = (type, templates) => templates.find(({ name }) => name === type);

export const applicationStatusColors = {
  CONFIRMED: 'var(--tag-green)',
  CONFIRMED_FOR_LATER: 'var(--tag-blue)',
  REFUSED: 'var(--tag-red)',
  PENDING: 'var(--tag-grey)',
  MAYBE: 'var(--tag-yellow)',
};

export const getLanguagesFromOutput = (output) =>
  output?.cards
    ? Object.entries(output.cards.reduce((acc, { language }) => ({ ...acc, [language]: (acc[language] || 0) + 1 }), {})).map(([language, count]) => ({
        language,
        count,
      }))
    : [];

export const getOutputWithGroupedCards = (outputs) =>
  outputs
    .map((output) => {
      const languagesCount = getLanguagesFromOutput(output);
      return languagesCount.map((languageCount) => ({
        id: output.id,
        filename: output.filename,
        language: languageCount.language,
        cards: output.cards.filter((card) => card.language === languageCount.language),
      }));
    })
    .flat();

const previousProjectPages = {
  LIST: ({ companyId, solutionId, solutionType } = {}, _, t) => [{ url: ROUTES.PROJECT_LIST.path({ companyId, solutionId, solutionType }), name: t('Videos') }],
  LIST_FOLLOWUP: ({ companyId, solutionId } = {}, search, t, { navigate, state } = {}) => [
    {
      func: () =>
        navigate(
          ROUTES.FOLLOWUP.path({ companyId, solutionId: search.followup }, pick(search, ['followup', 'menu', 'tab', 'from', 'to', 'status', 'isList'])),
          {
            state,
          }
        ),
      name: t('Videos'),
    },
  ],
  BROADCASTING: ({ companyId } = {}, _, t) => [{ url: ROUTES.BROADCASTING.path({ companyId }), name: t('Broadcasting') }],
  DASHBOARD: ({ companyId } = {}, _, t) => [{ url: ROUTES.DASHBOARD.path({ companyId }), name: t('Dashboard') }],
  FAVORITE_LIST: (_, __, t) => [{ url: ROUTES.PROJECT_FAVORITE_LIST.path(), name: t('Favorite videos') }],
  WORKFLOW_DASHBOARD: (_, { userId, id } = {}, t) => [
    {
      url: ROUTES.DASHBOARD_CUSM.path(
        {},
        {
          userId: userId,
        }
      ),
      name: t('Workflows'),
    },
    {
      url: ROUTES.DASHBOARD_CUSM.path(
        {},
        {
          userId: userId,
        }
      ),
      name: workflowNames[id]?.(t),
    },
  ],
  USM_DASHBOARD: (_, { tab }, t) => [{ url: ROUTES.DASHBOARD_CUSM.path(null, { tab }), name: t('Dashboard USM') }],
  OPERATOR_DASHBOARD: (_, __, t) => [{ url: ROUTES.WORKFLOW_OVERVIEW_OPERATOR.path(), name: t('Dashboard Operator') }],
  CAMPAIGN_OVERVIEW: ({ companyId, solutionId } = {}, _, t) => [{ url: ROUTES.CAMPAIGN_OVERVIEW.path({ companyId, solutionId }), name: t('Campaign overview') }],
};

export const getPreviousProjectPages = (queryParams, searchParams, t = (_) => _, options = {}) =>
  (previousProjectPages[searchParams?.previous || 'LIST'])(queryParams, searchParams, t, options);

/**
 * Compute permissions on a resource
 */
export const transformPermissions = (permissions) =>
  Array.isArray(permissions) ? (permissions || []).reduce((acc, action) => ({ ...acc, [action]: true }), {}) : permissions;

export const videoRatios = (t) => [
  { label: t('16:9 (Landscape)'), value: '16:9' },
  { label: t('1:1 (Square)'), value: '1:1' },
  { label: t('9:16 (Story)'), value: '9:16' },
  { label: t('3:4 (Tiny story)'), value: '3:4' },
  { label: t('2:3 (Small story)'), value: '2:3' },
  { label: t('2:1 (Stretched landscape)'), value: '2:1' },
  { label: t('4:5 (Portrait)'), value: '4:5' },
];

export const videoResolutions = (t) => [
  { label: t('4K'), value: 'R2160' },
  { label: t('1080p (Full HD)'), value: 'R1080' },
  { label: t('720p (HD)'), value: 'R720' },
  { label: t('Original'), value: 'ORIGINAL' },
];

export const isValidURL = (string) => {
  try {
    new URL(string);
  } catch {
    return false;
  }
  return true;
};

export const isValidEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const mailStatus = (t) => [
  { label: t('All'), value: 'ALL' },
  { label: t('Planned'), value: 'PLANNED' },
  { label: t('Sent'), value: 'SENT' },
];

export const objectToPrettifiedString = (object, tabQty = 0) => {
  const prefix0 = '    '.repeat(tabQty);
  const prefix1 = '    '.repeat(tabQty + 1);
  const prefix2 = '    '.repeat(tabQty + 2);

  return `{\n${prefix1}${Object.keys(object)
    .map((key) => {
      if (Array.isArray(object[key])) {
        return `"${key}": [\n${prefix2}${object[key].map((child) => `${objectToPrettifiedString(child, tabQty + 2)}`).join(`,\n${prefix2}`)}\n${prefix1}]`;
      }
      if (!!object[key] && typeof object[key] === 'object') {
        return `"${key}": ${objectToPrettifiedString(object[key], tabQty + 1)}`;
      }
      return `"${key}": ${JSON.stringify(object[key])}`;
    })
    .join(`,\n${prefix1}`)}\n${prefix0}}`;
};

const valueMap = {
  LIST: (value) => value?.join('||'),
  ADVANCED_LIST: (value) => value?.map((v) => `${v.title}${v.description ? `|| ${v.description}` : ''}`)?.join('||'),
};
const getValue = (type, value) => {
  const get = valueMap[type];
  return get ? get(value) : value;
};

export const searchFilter = (fullText) => (config) =>
  fullText
    ? [getValue(config.type, config.defaultValue), getValue(config.type, config.value), config.name].some(
        (text) => typeof text === 'string' && text.toLowerCase().includes(fullText.toLowerCase().trim())
      )
    : true;

const wait = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

export const retry = async (operation, options = {}) => {
  const { retries, delay, onError = () => {} } = options;

  return operation().catch((err) => {
    onError(err);
    if (retries > 0) {
      return wait(delay).then(() => retry(operation, { ...options, retries: retries - 1 }));
    } else {
      throw err;
    }
  });
};

export const updateUrlQueryParams = (values) => {
  const urlParams = new URLSearchParams(window.location.search);

  Object.entries(values).forEach(([query, value]) => {
    if (String(value)) {
      urlParams.set(query, String(value));
    } else {
      urlParams.delete(query);
    }
  });

  window.history.replaceState(null, null, `?${urlParams.toString()}`);
};

export const DEFAULT_TEXT_SUCCESS = 'Your action has been proceeded with success';
export const DEFAULT_TEXT_ERROR = 'Your action has failed';

export const unixFromDate = (date, hours = [0, 0, 0]) => Math.floor(date.setHours(...hours) / 1000);

export const dateFromUnix = (unix) => new Date(unix * 1000);

// eslint-disable-next-line
export const getCompanyIdFromUrl = () => window.location.pathname.match(/\/company\/([^\/]+)\//)?.[1];

export const getEncodedId = (type) => (id) => id && type ? window.btoa(`${type}:${id}`) : null;

export const hasValidTimeRange = (timeRange) => [timeRange.from, timeRange.to].filter(Boolean).length > 1;
