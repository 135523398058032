import groupBy from 'lodash/groupBy';
import moment from 'moment';

const groupData = (data) => {
  const groupByTime = groupBy(data, 'time');
  const groupByTimeAndType = Object.keys(groupByTime).reduce((acc, e) => ({ ...acc, [e]: groupBy(groupByTime[e], 'groupKey') }), {});
  return Object.keys(groupByTimeAndType).reduce((accumulator, e) => {
    const sumByTimeAndType = Object.keys(groupByTimeAndType[e]).reduce((acc, elem) => {
      const sum = groupByTimeAndType[e][elem].reduce((sum, el) => sum + el.value, 0);
      const type = groupByTimeAndType[e][elem][0].type;
      return [...acc, { time: e, type, groupKey: elem, value: sum }];
    }, []);
    return [...accumulator, ...sumByTimeAndType];
  }, []);
};

export const calculateRate = (data, title) => {
  const groupByTime = groupBy(data, 'time');
  return Object.keys(groupByTime).reduce((acc, e) => {
    const axis1 = groupByTime[e].find((e) => e.groupKey === 'axis1');
    const axis2 = groupByTime[e].find((e) => e.groupKey === 'axis2');

    return [
      ...acc,
      {
        time: e,
        rate: axis2?.value > 0 && axis1?.value > 0 ? Number(((axis2?.value / axis1?.value) * 100)?.toFixed(2)) : 0,
        type: title,
      },
    ];
  }, []);
};

export const joinDataByRange = (data, type) => {
  const transformData = {
    daily: data,
    weekly: groupData(data.map((e) => ({ ...e, time: moment(e.day * 24 * 3600 * 1000).format('WW-YYYY') }))),
    monthly: groupData(data.map((e) => ({ ...e, time: moment(e.day * 24 * 3600 * 1000).format('MM-YYYY') }))),
  };
  return transformData[type] || data;
};

export const getEmptyDays = (data, range) => {
  if (
    !range?.from || !range?.to || !Number.isFinite(range.from) || !Number.isFinite(range.to) || range.to < range.from) {
    return [];
  }

  const length = range.to - range.from + 1;
  if (length > 1000000) {
    return [];
  }

  try {
    return Array.from(
      { length },
      (_, i) => range.from + i
    ).filter(day => !data?.some?.(project => project?.day === day));
  } catch (error) {
    console.error('Error in getEmptyDays:', error);
    return [];
  }
}

export const colorTheme = {
  colors10: ['#FACDAA', '#F4A49E', '#EE7B91', '#E85285', '#BE408C', '#BE408C'],
  colors20: ['#FACDAA', '#F4A49E', '#EE7B91', '#E85285', '#BE408C', '#BE408C', '#942D93'],
};
