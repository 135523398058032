import { ApolloClient, InMemoryCache, ApolloLink, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';

import { relayStyleConcatPagination } from '@app/apolloPagination';
import cancelRequestLink from '@app/cancelableLink';
import { API_URL, GIT_REVISION, WS_URL } from '@app/config';
import { transformPermissions } from '@app/utils';

const client = new ApolloClient({
  name: 'backoffice',
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all',
    },
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
  version: GIT_REVISION,
  link: split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    new WebSocketLink({
      uri: `${WS_URL}subscriptions`,
      options: {
        reconnect: true,
      },
    }),
    ApolloLink.from([
      onError(({ response, graphQLErrors, networkErrors }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`));

          // const hasAclError = graphQLErrors.some((error) => error?.extensions?.code === 'FORBIDDEN');
          // if (hasAclError) {
          //   signIn();
          // }
        }

        if (networkErrors) console.error('networkErrors', networkErrors);
      }),
      cancelRequestLink,
      new ApolloLink((operation, forward) => {
        if (operation.variables) {
          const omitTypename = (key, value) => (key === '__typename' ? undefined : value);
          operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
        }
        return forward(operation).map((data) => {
          return data;
        });
      }),
      createUploadLink({
        uri: `${API_URL}graphql`,
        credentials: 'include',
      }),
    ])
  ),
  cache: new InMemoryCache({
    possibleTypes: {
      Solution: ['PlayerSolution', 'PlatformSolution', 'VSGSolution', 'TrackingSolution', 'ProcessingSolution', 'SmartUploaderSolution'],
      Localizable: ['PlatformSolution', 'VSGSolution'],
      Auditable: ['PlayerSolution', 'TrackingSolution', 'VSGSolution'],
      Automatable: ['PlayerSolution'],
      Log: ['SimpleLog', 'LogWithOutput', 'LogWithInput', 'LogWithSolution', 'LogWithProductReference'],
      Sequence: ['SequenceAudio', 'SequencePicture', 'SequenceVideo'],
      Audit: ['AuditPlayer', 'AuditTracking', 'AuditVsg'],
      Resource: ['PlatformSolution', 'Company', 'AbstractResource'],
    },
    typePolicies: {
      Query: {
        fields: {
          mails: relayStyleConcatPagination(),
          changelogs: relayStyleConcatPagination(),
          getConfigurationTemplates: {
            merge: false,
          },
          orders: relayStyleConcatPagination(),
          templateKeys: relayStyleConcatPagination(),
          templateValues: relayStyleConcatPagination(),
          getPresetValues: relayStyleConcatPagination(),
          externalVideos: relayStyleConcatPagination(),
          fetchExports: relayStyleConcatPagination(),
        },
      },
      ConfigurationTemplate: {
        fields: {
          presets: {
            merge: false,
          },
          keys: {
            merge: false,
          },
        },
      },
      AbstractResource: {
        keyFields: false,
      },
      User: {
        fields: {
          applications: relayStyleConcatPagination(),
          allowedActions: {
            read: transformPermissions,
          },
        },
      },
      OutsourcingData: {
        fields: {
          subtitlesPublished: relayStyleConcatPagination(),
          subtitlesNotPublished: relayStyleConcatPagination(),
        },
      },
      Company: {
        fields: {
          allowedActions: {
            read: transformPermissions,
          },
        },
      },
      PlatformSolution: {
        fields: {
          products: relayStyleConcatPagination(),
          applications: relayStyleConcatPagination(),
          orders: relayStyleConcatPagination(),
        },
      },
      Role: {
        fields: {
          actions: {
            read: transformPermissions,
          },
        },
      },
      Project: {
        fields: {
          allowedActions: {
            read: transformPermissions,
          },
        },
      },
      ProjectOutput: {
        fields: {
          allowedActions: {
            read: transformPermissions,
          },
        },
      },
      Campaign: {
        fields: {
          applications: relayStyleConcatPagination(),
          orders: relayStyleConcatPagination(),
          projects: relayStyleConcatPagination(),
        },
      },
    },
  }),
});

export default client;
