import { faFacebookF, faTwitter, faYoutube, faInstagramSquare, faSnapchatGhost, faChrome, faFirefox, faSafari } from '@fortawesome/free-brands-svg-icons';
import { faCalendar, faClock, faImage, faBell, faTrashAlt, faSave, faFileVideo } from '@fortawesome/free-regular-svg-icons';
import {
  faHeadphonesAlt,
  faAngleDown,
  faBars,
  faBell as faBellSolid,
  faChartBar,
  faChartArea,
  faEdit,
  faClock as faClockSolid,
  faCircle,
  faCheck,
  faChevronUp,
  faChevronRight,
  faChevronDown,
  faChevronLeft,
  faFolderOpen,
  faSearch,
  faTimes,
  faPlay,
  faPause,
  faRedo,
  faForward,
  faBackward,
  faEye,
  faEyeSlash,
  faCode,
  faUnlink,
  faLink,
  faReply,
  faShare,
  faPalette,
  faFile,
  faToggleOn,
  faLanguage,
  faList,
  faQuoteRight,
  faLayerGroup,
  faGlobe,
  faExclamationTriangle,
  faInfoCircle,
  faCircleNotch,
  faBatteryHalf,
  faHeart,
  faHeartBroken,
  faArrowDown,
  faArrowUp,
  faArchive,
  faShieldAlt,
  faHeartbeat,
  faMagic,
  faCodeBranch,
  faClipboardList,
  faPencilAlt,
  faPaperclip,
  faBan,
  faCogs,
  faEllipsisV,
  faBold,
  faStrikethrough,
  faHighlighter,
  faAlignLeft,
  faMinus,
  faFont,
  faUserCog,
  faRocket,
  faCompressAlt,
  faPlus as faSolidPlus,
  faSearchMinus,
  faSearchPlus,
  faQuestion,
  faVideo as faVideoSolid,
  faPen,
  faCaretDown as faCaretDownSolid,
  faCaretUp as faCaretUpSolid,
  faFlag,
  faBookmark,
  faInbox,
  faFileAlt,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { faGlobe as faGlobeLight, faEyeDropper, faFilm, faQrcode, faPlus, faThLarge, faInventory } from '@fortawesome/pro-light-svg-icons';
import {
  faEnvelope,
  faEnvelopeOpen,
  faCommentAltLines,
  faShoppingCart,
  faCog,
  faShareAlt,
  faEllipsisH,
  faQuestionSquare,
  faVideo,
  faCreditCardBlank,
  faExternalLinkSquareAlt,
  faGift,
  faKey,
  faCloudDownloadAlt,
  faCloudUploadAlt,
  faHourglassHalf,
  faKeyboard,
  faBullseyeArrow,
  faListAlt,
  faCopy,
  faPhoneAlt,
  faBirthdayCake,
  faMapMarkerAlt,
  faSparkles,
  faFileUpload,
  faCheckSquare,
  faExclamationSquare,
  faCommentAlt,
  faUpload,
  faCamera,
  faMobile,
  faDesktop,
  faItalic,
  faUnderline,
  faFileCertificate,
  faCalendarExclamation,
  faCalendarPlus,
  faCommentAltEdit,
  faDoorOpen,
  faSitemap,
  faUserPlus,
  faRocketLaunch,
  faFlagAlt,
  faChartLine,
  faCamcorder,
  faRepeat,
  faAnalytics,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowToBottom,
  faArrowRight,
  faExternalLink,
  faLocation,
  faBoxCheck,
  faSignOut,
  faBrowser,
  faFontCase,
  faUsers,
  faUser,
  faSquareFull,
  faCut,
  faBallot,
  faPaperPlane,
  faRocketLaunch as faRocketLaunchSolid,
  faUndo,
  faHourglassHalf as faHourglassHalfSolid,
  faGift as faGiftSolid,
  faFileExport as faFileExportSolid,
  faPaperPlane as faPaperPlaneSolid,
  faTruck,
  faHouse,
  faWandMagic,
  faPlusCircle,
  faTrashAlt as faTrashAltPro,
  faClosedCaptioning,
  faHomeAlt,
} from '@fortawesome/pro-solid-svg-icons';

import { ReactComponent as ProductIcon } from './assets/productImage.svg';
import { ReactComponent as SkeepersLogoShort } from './assets/skeepersIconShort.svg';
import { ReactComponent as SkeepersLogo } from './assets/skeepersLogo.svg';
import { ReactComponent as SpreadIcon } from './assets/spreadIcon.svg';
import { ReactComponent as videoEditing } from './assets/videoEditing.svg';

const fill = (c) => ({ fill: c });

export const icons = {
  ANCHORDISABLED: faUnlink,
  ANCHORENABLED: faLink,
  PREVIOUSFRAME: faBackward,
  PLAY: faPlay,
  PAUSE: faPause,
  NEXTFRAME: faForward,
  ADD: faPlus,
  AUDIO: faHeadphonesAlt,
  ARROW: faAngleDown,
  BACK: faExternalLinkSquareAlt,
  BURGER: faBars,
  CLOSE: faTimes,
  DATA: faChartBar,
  DATA_GRAPH: faChartArea,
  DOWNLOAD: faArrowToBottom,
  DROPDOWN: faChevronDown,
  EDIT: faEdit,
  EMAIL: faEnvelope,
  EMAIL_OPEN: faEnvelopeOpen,
  EYE: faEye,
  EYESLASH: faEyeSlash,
  EXPORT: faCloudDownloadAlt,
  IMPORT: faCloudUploadAlt,
  CALENDAR: faCalendar,
  COMMENT: faCommentAltLines,
  CIRCLE: faCircle,
  CIRCLE_PLUS: faPlusCircle,
  CHECK: faCheck,
  CHEVRON_LEFT: faChevronLeft,
  CHEVRON_RIGHT: faChevronRight,
  CHEVRON_UP: faChevronUp,
  CHEVRON_DOWN: faChevronDown,
  CLOCK: faClock,
  VIDEO: faVideo,
  OTHER: faFolderOpen,
  IMAGE: faImage,
  GENERAL: faHomeAlt,
  CASTING: faSearch,
  PLATFORM: faGift,
  TRACKING: faLocation,
  VSG: faShoppingCart,
  PLAYER: faVideo,
  PENDING: faHourglassHalf,
  REFRESH: faRedo,
  SAVE: faSave,
  SETTING: faCog,
  SHARE: faShareAlt,
  DONE: faCheck,
  NOTIFICATION: faBell,
  TO_MODERATE: faBellSolid,
  TRASH: faTrashAlt,
  TRASH_SOLID: faTrashAltPro,
  WAITING: faEllipsisH,
  UNKNOWN: faQuestionSquare,
  EXTERNAL: faExternalLink,
  SUBTITLES: faCreditCardBlank,
  CLOSEDCAPTIONING: faClosedCaptioning,
  FAST_FORWARD: faForward,
  REWIND: faBackward,
  USERS: faUsers,
  KEY: faKey,
  DEVELOPPER: faCode,
  DASHBOARD: faHomeAlt,
  REDO: faShare,
  UNDO: faReply,
  KEYBOARD: faKeyboard,
  COMPANY: faBullseyeArrow,
  CARDS: faListAlt,
  COPY: faCopy,
  PALETTE: faPalette,
  LINK: faLink,
  FILE: faFile,
  TOGGLE: faToggleOn,
  LANGUAGE: faGlobe,
  TRANSLATE: faLanguage,
  LIST: faList,
  QUOTE: faQuoteRight,
  LAYER: faLayerGroup,
  WARNING: faExclamationTriangle,
  INFO: faInfoCircle,
  SPINNER: faCircleNotch,
  BATTERY: faBatteryHalf,
  PHONE: faPhoneAlt,
  BIRTHDAY: faBirthdayCake,
  PLACE: faMapMarkerAlt,
  FACEBOOK: faFacebookF,
  TWITTER: faTwitter,
  YOUTUBE: faYoutube,
  SPARKLES: faSparkles,
  SPARKLES_MAGIC: faWandMagic,
  UPLOAD: faFileUpload,
  SQUARE: faSquareFull,
  CHECK_SQUARE: faCheckSquare,
  EXCLAMATION_SQUARE: faExclamationSquare,
  DIALOG: faCommentAlt,
  FAV: faHeart,
  UNFAV: faHeartBroken,
  ARROW_DOWN: faArrowDown,
  ARROW_RIGHT: faArrowRight,
  ARROW_UP: faArrowUp,
  ARCHIVED: faArchive,
  AVAILABLE: faBoxCheck,
  SHIELD: faShieldAlt,
  HEARTBEAT: faHeartbeat,
  MAGIC: faMagic,
  VERSION: faCodeBranch,
  TODO: faClipboardList,
  LOGOUT: faSignOut,
  USER: faUser,
  PENCIL: faPencilAlt,
  ATTACHMENT: faPaperclip,
  INSTAGRAM: faInstagramSquare,
  SNAPCHAT: faSnapchatGhost,
  BROWSER: faBrowser,
  CHROME: faChrome,
  FIREFOX: faFirefox,
  SAFARI: faSafari,
  BAN: faBan,
  COGS: faCogs,
  USER_COG: faUserCog,
  UPLOADER: faUpload,
  SMART_UPLOADER: faRocketLaunch,
  PICTURE: faCamera,
  DRAGGABLE: faEllipsisV,
  MOBILE: faMobile,
  DESKTOP: faDesktop,
  BOLD: faBold,
  ITALIC: faItalic,
  UPPERCASE: faFontCase,
  UNDERLINE: faUnderline,
  STRIKETHROUGH: faStrikethrough,
  HIGHLIGHT: faHighlighter,
  ALIGN_LEFT: faAlignLeft,
  MINUS: faMinus,
  CERTIFICATE: faFileCertificate,
  FONT: faFont,
  CALENDAR_EXCLAMATION: faCalendarExclamation,
  CALENDAR_PLUS: faCalendarPlus,
  FILE_VIDEO: faFileVideo,
  BOOST: faRocket,
  BUBBLE_EDIT: faCommentAltEdit,
  DOOR: faDoorOpen,
  SITEMAP: faSitemap,
  GLOBE: faGlobeLight,
  FILM: faFilm,
  PICKER: faEyeDropper,
  QRCODE: faQrcode,
  CUT: faCut,
  FIT_TO_SCREEN: faCompressAlt,
  PLUS: faSolidPlus,
  USER_PLUS: faUserPlus,
  ROCKET_LAUNCH: faRocketLaunch,
  FLAG_ALT: faFlagAlt,
  FLAG: faFlag,
  GIFT: faGift,
  GIFT_SOLID: faGiftSolid,
  ZOOM_MINUS: faSearchMinus,
  ZOOM_PLUS: faSearchPlus,
  BALLOT: faBallot,
  LATER: faClockSolid,
  MAYBE: faQuestion,
  PEN: faPen,
  PAPER_PLANE: faPaperPlane,
  UNDO_BACK: faUndo,
  LIST_VIEW: faBars,
  GRID_VIEW: faThLarge,
  STATS: faChartLine,
  HOURGLASS_SOLID: faHourglassHalfSolid,
  CAMCORDER: faCamcorder,
  CARET_DOWN_SOLID: faCaretDownSolid,
  CARET_UP_SOLID: faCaretUpSolid,
  FILE_EXPORT_SOLID: faFileExportSolid,
  PAPER_PLANE_SOLID: faPaperPlaneSolid,
  ROCKET_LAUNCH_SOLID: faRocketLaunchSolid,
  VIDEO_SOLID: faVideoSolid,
  TRUCK: faTruck,
  HOUSE: faHouse,
  BOOKMARK: faBookmark,
  REPEAT: faRepeat,
  INBOX: faInbox,
  FILE_ALT: faFileAlt,
  ANALYTICS: faAnalytics,
  INVENTORY: faInventory,
  TIMES_CIRCLE: faTimesCircle,
};

export const oldIcons = {
  PRODUCT: {
    Icon: ProductIcon,
    iconStyle: fill,
  },
  SPREAD: {
    Icon: SpreadIcon,
    iconStyle: fill,
  },
  VIDEO_EDITING: {
    Icon: videoEditing,
    iconStyle: fill,
  },
  SKEEPERS_LOGO: {
    Icon: SkeepersLogo,
    iconStyle: fill,
  },
  SKEEPERS_LOGO_SHORT: {
    Icon: SkeepersLogoShort,
    iconStyle: fill,
  },
};

export default icons;
